import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import BaseLayout from '../../components/layout/base-layout';

import BookList from '../../components/books/list';
import CatalogHeader from '../../components/common/catalog-header';
import PageColumn from '../../components/layout/page-column';
import Pagination from '../../components/common/pagination';
import Section from '../../components/layout/section';

const KindleDiscountListingPage = (props) => {
  const start = (props.pageContext.page - 1) * props.pageContext.perPage;
  const filteredBookNodes = props.data.books.edges
                                 .slice(start, start + props.pageContext.perPage)
                                 .map(({ node }) => node);
  return (
    <BaseLayout>
      <Helmet>
        <title>Kindle Discounts</title>
        <meta
          property="og:title"
          content="Kindle Discounts"
        />
      </Helmet>
      <CatalogHeader
        label={(
          <>
            Cookbooks <span className="highlight">Priced Under $5</span> on Kindle
          </>
        )}
      />
      <Section topSpacing="section">
        <PageColumn>
          <BookList
            bookNodes={ filteredBookNodes }
          />
        </PageColumn>
      </Section>
      <Section topSpacing="section" bottomSpacing="section">
        <PageColumn>
          <Pagination
            pathStem={`/books/`}
            page={props.pageContext.page}
            perPage={props.pageContext.perPage}
            totalPages={props.pageContext.totalPages}
          />
        </PageColumn>
      </Section>
    </BaseLayout>
  );
}

export default KindleDiscountListingPage;

export const query = graphql`
{
  books: allAirtable(filter: {table: {eq: "Books"}, data: {Published: {eq: true}, Kindle_Price: {lt: 5}}},
              sort: {fields: data___Last_Updated, order: DESC}) {
    edges {
      node {
        data {
          ...BookForListing
        }
      }
    }
  }
}
`;